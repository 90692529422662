<template>
  <v-dialog v-model="dialog" width="1000" persistent transition="dialog-bottom-transition">
    <v-card tile>
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Adicionar Botão</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-container>
        <v-form ref="form" v-model="valid" class="px-5" lazy-validation style="background-color: none;">
          <v-row>
            <v-col cols="6">
              <mf-text-input v-model="homeButton.title" :rules="[notEmptyRule]" label="Título" outlined />
            </v-col>
            <v-col cols="6">
              <mf-text-input v-model="homeButton.flagText" :disabled="homeButton.icon == ''" label="FlagText" outlined />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-row class="mt-n6" no-gutters justify="end">
                <v-col cols="auto">
                  <v-icon small @click="newWindowRedirect('https://fontawesome.com/icons')">info</v-icon>
                </v-col>
              </v-row>
              <mf-text-input v-model="homeButton.icon" :rules="[notEmptyRule]" label="Ícone" outlined />
            </v-col>
            <v-col cols="6">
              <mf-select v-model="homeButton.icon_type" :rules="[notEmptyRule]" :items="iconTypes" label="Tipo do ícone" />
            </v-col>
            <v-col cols="6">
              <mf-text-input v-model="homeButton.link" :rules="[notEmptyRule]" label="Link" outlined />
            </v-col>
            <v-col cols="6">
              <mf-select v-model="homeButton.type" :rules="[notEmptyRule]" :items="iconTypes" label="Tipo do link" />
            </v-col>
            <v-col cols="6">
              <mf-number-input v-model="homeButton.shortcutOrder" :rules="[notEmptyRule]" label="Ordem Atalho" outlined />
            </v-col>
            <v-col cols="6">
              <mf-number-input v-model="homeButton.triggerOrder" :rules="[notEmptyRule]" label="Ordem Gatilho" outlined />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="6">
                  <v-row justify="center">
                    <v-col cols="auto">
                      <v-checkbox v-model="club" label="Clube" color="primary" hide-details></v-checkbox>
                    </v-col>
                    <v-col cols="auto">
                      <v-checkbox v-model="ecommerce" label="E-commerce" color="primary" hide-details></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row style="justify-content:center">
            <mf-button class="ma-2" icon="send" :label="loading ? 'Adicionando...' : 'Salvar Botão'" color="primary" @click="addHomeButton"> </mf-button>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { MUTATION_INSERT_BUTTON, QUERY_AVAILABLE_ICONS } from '@/modules/accounts/graphql'

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    accountId: {
      type: String,
      default: ''
    },
    appType: {
      type: String,
      default: ''
    },
    listButtons: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    notEmptyRule: v => !!v || 'Campo obrigatório',
    valid: false,
    iconTypes: ['INTERNAL', 'EXTERNAL'],
    homeButton: {
      title: '',
      subtitle: '',
      icon: '',
      link: '',
      flagText: '',
      icon_type: 'INTERNAL',
      type: 'INTERNAL',
      shortcutOrder: '',
      triggerOrder: ''
    },
    type: 'default',
    availableIcons: [],
    loading: false,
    button_type: '',
    club: false,
    ecommerce: false
  }),
  computed: {
    position() {
      if (this.listButtons.length === 0) {
        return 1
      }
      return this.listButtons[this.listButtons.length - 1].position + 1
    }
  },
  apollo: {
    adminGetAvailableIcons: {
      query: QUERY_AVAILABLE_ICONS,
      fetchPolicy: 'network-only',
      skip() {
        return !this.accountId
      },
      async update({ adminGetAvailableIcons }) {
        if (!adminGetAvailableIcons) return []
        this.availableIcons = adminGetAvailableIcons.icons
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id'),
            accountId: this.accountId
          },
          uri: this.$microservicesUrls['app_config']
        }
      }
    }
  },
  methods: {
    close() {
      this.$refs.form.reset()
      this.$emit('close')
    },
    newWindowRedirect(link) {
      return window.open(link, '_blank')
    },
    validateInput() {
      let variables = {
        title: this.homeButton.title,
        link: this.homeButton.link,
        icon: this.homeButton.icon,
        flagText: this.homeButton.flagText,
        ecommerce: this.ecommerce,
        club: this.club,
        type: this.homeButton.type,
        icon_type: this.homeButton.icon_type,
        triggerOrder: this.homeButton.triggerOrder,
        shortcutOrder: this.homeButton.shortcutOrder
      }
      if (this.homeButton.flagText !== '') {
        this.homeButton.subtitle = ''
        variables.flagText = this.homeButton.flagText
        variables.subtitle = ''
      }
      if (variables.flagText === '') delete variables.flagText
      if (variables.subtitle === '') delete variables.subtitle
      const input = Object.assign(
        {},
        { account_id: this.accountId },
        { app_type: this.appType },
        { button: variables },
        this.appType === 'default' ? { button_title: this.homeButton.title } : { index: 0 }
      )
      return { input }
    },
    async addHomeButton() {
      if (!this.$refs.form.validate())
        return this.$alert({
          alert_message: 'Falha ao validar campos',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      this.loading = true
      const variables = this.validateInput()
      let context = {
        uri: this.$microservicesUrls['app_config']
      }
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_INSERT_BUTTON,
          variables,
          context
        })
        setTimeout(() => {
          this.$refs.form.reset()
          this.homeButton = {
            title: '',
            subtitle: '',
            icon: '',
            link: '',
            flagText: '',
            icon_type: 'INTERNAL',
            type: 'INTERNAL'
          }
          this.$alert({
            alert_message: 'Botão criado com sucesso',
            alert_title: 'Sucesso!',
            alert_color: 'success',
            alert_icon: 'mdi-check-circle'
          })
          this.$apollo.queries.adminGetAvailableIcons.refetch()
          this.loading = false
          this.$emit('close')
        }, 1000)
      } catch (err) {
        this.loading = false
        console.log(err.message)
        err.message === 'GraphQL error: Icon already exists'
          ? this.$alert({
              alert_message: 'Botão já criado com este ícone',
              alert_title: 'Erro!',
              alert_color: 'error',
              alert_icon: 'mdi-close-circle'
            })
          : this.$alert({
              alert_message: 'Falha ao criar botão',
              alert_title: 'Erro!',
              alert_color: 'error',
              alert_icon: 'mdi-close-circle'
            })
      }
    }
  }
}
</script>
